
import { interfaceService } from "@/services/interface";
import { Error } from "@/interfaces/services/error";
import { Component, Vue } from "vue-property-decorator";
import { debounce } from "debounce";
import { errorService } from "@/services/error";
import TitleBasic from "@/components/layout/TitleBasic.vue";

@Component({
  components: {
    TitleBasic
  }
})
export default class Main extends Vue {
  loading = true;
  error: string | null = null;
  items: Error[] = [];
  itemsCount = 0;
  itemsPage = 1;
  itemsPerPage = 20;

  search: string | null = null;
  searchResolve = debounce(this.updateSearch, 500);

  get title() {
    return this.hasSearchQuery
      ? `${this.$t("PAGE_TITLE_ERRORS_SEARCH_TEXT")} "${this.search}"`
      : this.$t("COMMON_INTERFACE_ERRORS_TEXT");
  }

  get itemsLength() {
    return Math.ceil(this.itemsCount / this.itemsPerPage);
  }

  get hasItems() {
    return this.itemsCount > 0;
  }

  get searchQuery() {
    const qs = this.$router.currentRoute.query["s"];
    return qs
      ? Array.isArray(qs)
        ? qs.length > 0
          ? qs[0]
          : null
        : qs
      : null;
  }

  get endpoint() {
    const qep = this.$router.currentRoute.query["endpoint"];
    return qep
      ? Array.isArray(qep)
        ? qep.length > 0
          ? qep[0]
          : null
        : qep
      : null;
  }

  get hasSearchQuery() {
    return this.searchQuery != null;
  }

  get hasEndpoint() {
    return this.endpoint != null;
  }

  created() {
    this.search = this.searchQuery;
    this.itemsLoadData();
  }

  itemsLoadData() {
    this.error = null;
    this.loading = true;
    errorService
      .list(this.itemsPerPage, this.itemsPage, {
        s: this.search ? [this.search] : undefined,
        ep: this.endpoint || undefined
      })
      .then(data => {
        this.items = data.items || [];
        this.itemsCount = data.count;
      })
      .catch(e => (this.error = e.code))
      .finally(() => (this.loading = false));
  }

  updateSearch(s: string) {
    this.search = s;
    this.$router.replace({ query: { s } });
    this.itemsLoadData();
  }

  getDisplayDateFormat(date: string) {
    return new Date(date).toLocaleString(interfaceService.getLanguage(), {
      dateStyle: "medium",
      timeStyle: "medium"
    });
  }
}
